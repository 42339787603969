import React from 'react'
import Link from 'gatsby-link'
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

export default function Template({data}) {
    const post = data.markdownRemark

    return (
        <Layout>
            <BlogWrapper>
            <SEO title={post.frontmatter.title} />
            <h1>{post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{__html: post.html}} />
            <br />
        </BlogWrapper>
        </Layout>
    )
}

export const postRaceQuery = graphql `
    query BlogPostByPathRaces($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path }}){
            html
            frontmatter {
                path
                title
                author
                date
                topic
                desc
            }
        }
    }
` 

const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 60px;
  margin-left: 200px;
  margin-right: 200px;
  text-align: left;
  div {
      box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.2), 0 16px 34px 0 rgba(0, 0, 0, 0.19);
      padding: 25px;
  }
  a {
    color: black;
  }
  h1 {
  font-family: 'Cairo', sans-serif;
      font-weight: 900;
  }

  @media (max-width: 480px) {
      grid-template-columns: 1fr;
      margin-left: 20px;
      margin-right: 20px;
      
      .display-1 {
        font-size: 2.4rem;
        text-align: left;
      }
    }
`




